<div class="modal-container">
  <div class="content-box">
    <div class="modal-header">
      <kite-label class="title">Batch Add User</kite-label>
      <kite-label class="sub-title"></kite-label>
    </div>
    <div class="modal-info">
      <div *ngIf="!isLoading; else loader">
        <div *ngIf="!isSuccess && !errorMessage">
          <span class="batch-text"
            >To batch add users you will need to upload a csv file with the
            following fields for each user in this order: PID, First Name, Last
            Name, Email, Company Id, Role Names (
          </span>
          <span class="batch-highlighted-text"
            >only Role Names are separated by a |
          </span>
          <span class="batch-text">)</span>
        </div>
        <div class="success-message" *ngIf="!isLoading && isSuccess">
          <kite-icon
            [icon]="'ki-checkmark'"
            [fill]="'green'"
            [title]="'ki-chevron-right-circle-f Icon'"
            [size]="'5em'"
            >...</kite-icon
          >
          <span>Users Added</span>
        </div>
        <div class="error-message" *ngIf="!isLoading && errorMessage">
          <kite-icon
            [icon]="'ki-x'"
            [fill]="'red'"
            [title]="'ki-chevron-right-circle-f Icon'"
            [size]="'5em'"
            >...</kite-icon
          >
          <span>{{ errorMessage }}</span>
        </div>
      </div>
    </div>
    <div class="batch-button-group">
      <input
        class="file-input"
        id="fileInput"
        #fileInput
        (change)="onSelectFile($event)"
        type="file"
        multiple="false"
        accept=".csv"
      />
      <kite-label>{{ fileName }}</kite-label>
      <kite-button-box>
        <kite-button
          class="button"
          id="add-user-button"
          (click)="handleCancel()"
          buttonStyle="secondary"
          minSize="lg"
          >Cancel
        </kite-button>
        <div *ngIf="!file || (file && errorMessage)">
          <kite-button
            class="button"
            id="add-user-button"
            (click)="handleSelect()"
            buttonStyle="primary"
            minSize="lg"
            >Select File
          </kite-button>
        </div>
        <div *ngIf="file && !isSuccess && !errorMessage">
          <kite-button
            class="button"
            id="add-user-button"
            (click)="onUploadFile()"
            buttonStyle="primary"
            minSize="lg"
            >Upload File</kite-button
          >
        </div>
        <div *ngIf="file && isSuccess">
          <kite-button
            class="button"
            id="add-user-button"
            (click)="handleCancel()"
            buttonStyle="primary"
            minSize="lg"
            >Exit</kite-button
          >
        </div>
      </kite-button-box>
    </div>
  </div>
</div>
<ng-template #loader>
  <div class="loader">
    <kite-progress-indicator [progress]="null"></kite-progress-indicator>
  </div>
</ng-template>
