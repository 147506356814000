import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserManagementService } from '../../services/user-management.service';

@Component({
  selector: 'app-batch-add-user-dialog',
  templateUrl: './batch-add-user-dialog.component.html',
  styleUrl: './batch-add-user-dialog.component.scss',
})
export class BatchAddUserDialogComponent {
  fileName = '';
  file: File | undefined;
  isLoading = false;
  isSuccess = false;
  errorMessage: string | undefined;

  constructor(
    public dialogRef: MatDialogRef<BatchAddUserDialogComponent>,
    private userManagementService: UserManagementService
  ) {}
  handleCancel() {
    this.dialogRef.close();
  }

  handleSelect() {
    this.errorMessage = undefined;
    document.getElementById('fileInput')?.click();
  }

  onSelectFile(event: any) {
    this.isSuccess = false;
    this.errorMessage = '';
    const filesUploaded: FileList = event.target.files;
    this.fileName = filesUploaded[0].name;
    this.file = filesUploaded[0];
  }

  onUploadFile() {
    if (this.file) {
      this.isLoading = true;
      this.userManagementService.bulkAddUpdateUser(this.file).subscribe({
        next: () => {
          this.isLoading = false;
          this.isSuccess = true;
          this.fileName = '';
        },
        error: (error) => {
          this.isLoading = false;
          if (error.error?.message || error.error?.details?.[0]?.msg) {
            this.errorMessage = error.error?.message
              ? error.error?.message
              : error.error?.details[0]?.msg;
          } else {
            this.errorMessage = error.statusText;
          }
          this.fileName = '';
          this.file = undefined;
        },
      });
    }
  }
}
